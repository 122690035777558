<template>
  <div>
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb fast-plataforma-iuea-hide">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Comissões</a>
            </li>
          </ul>
          <ul class="fd-app-breadcrumb d-none fast-menu-lives-aluno-iuea">
            <li class="fd-app-breadcrumb-item aluno_font_color">
              <a
                :href="'/minhas-plataformas'"
                @click.prevent="$router.push('/minhas-plataformas')"
              >IUEA</a>
            </li>
            <li class="fd-app-breadcrumb-item aluno_font_color active">
              <a href="#">Comissões</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
        <section class="fd-app-welcome">
          <h1>
            Minhas
            <span>comissões</span>
          </h1>
          <img :src="require('@/assets/images/form-effect-01.png')">

          <div class="row mt-4">
            
            <div class="col-lg-12 col-sm-12">
              <div class="row">
                <b-card no-body>
                  <b-tabs card>
                    <b-tab
                      title="Comissão"
                      active
                      v-if="$store.state.fastPermissoes.aluno != 'S'"
                    >
                      <b-card-text>
                        <div class="row">
                          <!-- COMISSÃO -->
                          <div v-if="!fastPessoaComissao.loading" class="col-12">
                            <div class="row">
                              <div class="col-sm-12 col-md-12 col-lg-3 mt-4">
                                <label>Plataforma:</label>
                                <select :class="fastFiltro.plataforma.escolhida ? 'form-control border border-danger text-danger' : 'form-control'" v-model="fastFiltro.plataforma.escolhida" @change="alteraDataFiltro()">
                                  <option value="">Todas</option>
                                  <option v-for="(nome_plataforma, index) in fastFiltro.plataforma.lista" :key="index" :value="nome_plataforma" >
                                    {{nome_plataforma}}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-sm-12 col-md-12 col-lg-3 mt-4">
                                <label>Ano repasse:</label>
                                <select :class="'form-control'" v-model="fastFiltro.anoPagamento.escolhido" @change="alteraDataFiltro()">
                                  <option v-for="(ano, index) in fastFiltro.anoPagamento.lista" :key="index" :value="ano" >
                                    {{ano}}
                                  </option>
                                </select>
                              </div>

                              <div class="col-sm-12 col-md-12 col-lg-3 mt-4">
                                <label>Mês repasse:</label>
                                <select :class="'form-control'" v-model="fastFiltro.mesPagamento.escolhido" @change="alteraDataFiltro()">
                                  <option v-for="(mes, index) in fastFiltro.mesPagamento.lista" :key="index" :value="index" >
                                    {{mes}}
                                  </option>
                                </select>
                              </div>

                              <div class="col-sm-12 col-md-12 col-lg-3 mt-4">
                                <label>Dia repasse:</label>
                                <select :class="'form-control'" v-model="fastFiltro.diaPagamento.escolhido" @change="alteraDataFiltro()">
                                  <option v-for="(dia, index) in fastFiltro.diaPagamento.lista" :key="index" :value="index" >
                                    {{dia}}
                                  </option>
                                </select>
                              </div>                              

                            </div>
                          </div>
                          <div class="col-12 mt-4">
                            <div class="row mb-4">
                              <div class="col-sm-12 col-md-12 col-lg-3">
                                <div class="card border-left-primary shadow h-100">
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-12">
                                        <div
                                          class="text-xs font-weight-bold text-secondary text-uppercase mb-1 text-center"
                                        >
                                          Total no período
                                          <div>
                                            R$ {{exibeTotalPeriodo()}}
                                          </div> 
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-3">
                                <div class="card border-left-primary shadow h-100">
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-12">
                                        <div
                                          class="text-xs font-weight-bold text-success text-uppercase mb-1 text-center"
                                        >
                                          Recebido no período
                                          <div>
                                            R$ {{exibeRecebidoPeriodo()}}
                                          </div> 
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-3">
                                <div class="card border-left-primary shadow h-100">
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-12">
                                        <div
                                          class="text-xs font-weight-bold text-warning text-uppercase mb-1 text-center"
                                        >
                                          A receber no período
                                          <div>
                                            R$ {{exibeReceberPeriodo()}}
                                          </div> 
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-3">
                                <div class="card border-left-primary shadow h-100">
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-12">
                                        <div
                                          class="text-xs font-weight-bold text-success text-uppercase mb-1 text-center"
                                        >
                                          Acumulados
                                          <div>
                                            R$ {{exibeRecebimentosAcumulado()}}
                                          </div> 
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-4 text-center mt-4">
                            <label>Status:</label>
                            <select :class="fastFiltro.status.escolhido ? 'form-control border border-danger text-danger' : 'form-control'" v-model="fastFiltro.status.escolhido" @change="alteraDataFiltro()">
                              <option value="">Todos</option>
                              <option value="P" >
                                Pago
                              </option>
                              <option value="A" >
                                Pendente
                              </option>
                              <option value="V" >
                                Vencido
                              </option>
                              <option value="C" >
                                Cancelado
                              </option>
                            </select>
                          </div>
                            <div class="col-12 text-center mt-4">
                              <h4 v-if="!fastPessoaComissao.loading">{{fastPessoaComissao.filtro.length}} registros encontrados</h4>
                            </div>
                            <div class="col-12 table-responsive mt-4">
                              <table class="table table-sm">
                                <thead class="thead-dark">
                                  <tr class="text-center">
                                    <th>
                                      <small class="font-weight-bold">Plataforma</small>
                                    </th> 
                                    <th>
                                      <small class="font-weight-bold">Emissão</small>
                                    </th>
                                    <th>
                                      <small class="font-weight-bold">Pagamento</small>
                                    </th>  
                                    <th>
                                      <small class="font-weight-bold">Registro</small>
                                    </th>
                                    <th>
                                      <small class="font-weight-bold">Invoice</small>
                                    </th> 
                                    <th>
                                      <small class="font-weight-bold">Parcela</small>
                                    </th>
                                    <th>
                                      <small class="font-weight-bold">Comissão</small>
                                    </th> 
                                    <th>
                                      <small class="font-weight-bold">Status</small>
                                    </th>  
                                    <th>
                                      <small class="font-weight-bold">Tipo</small>
                                    </th>                                   
                                    
                                  </tr>
                                </thead>
                                <tbody v-if="fastPessoaComissao.loading">
                                  <tr>
                                    <td colspan="9" class="text-center">
                                      <b-icon
                                    icon="gear-fill"
                                    animation="spin"
                                  /> Carregando boletos
                                    </td>
                                  </tr>
                                </tbody> 
                                <tbody v-else-if="fastPessoaComissao.paginacao.length">
                                  <tr v-for="(e, index) in fastPessoaComissao.paginacao" :key="index">   
                                    <td class="text-center">
                                      <span><small>{{e.nome_plataforma}}</small> </span> 
                                    </td>  
                                    <td class="text-center">
                                      <span v-if="e.data_registro"><small>{{formataDataT(e.data_registro)}}</small> </span> 
                                      <span v-else><small class="text-danger">Sem data</small> </span> 
                                    </td>   
                                    <td class="text-center">
                                      <span v-if="e.data_pagamento"><small>{{formataDataT(e.data_pagamento)}}</small> </span> 
                                      <span v-else-if="!e.cancelado_parcela"><small class="text-warning">Pendente</small> </span> 
                                      <span v-else><small class="text-secondary">Sem data</small> </span> 
                                    </td>   
                                    <td class="text-center">
                                      <span v-if="e.forma_pagamento == 'B'"><small>Boleto</small></span>
                                      <span v-else-if="e.forma_pagamento == 'C'"><small>Cartão</small></span>
                                      <span v-else-if="e.forma_pagamento == 'T'"><small>Transferência</small></span>
                                      <span v-else-if="e.forma_pagamento == 'P'"><small>PIX</small></span>
                                    </td>
                                    <td class="text-center">
                                      <small>#{{e.id_invoice}}</small>
                                    </td>
                                    <td class="text-center">
                                      <small>{{e.parcela}}/{{e.nr_parcelas}}</small>
                                    </td>
                                    <td class="text-center">
                                      <span v-if="e.valor_parcela_total">
                                        <small class="d-none">{{e.percentual}}% de R$ {{formataPreco(e.total)}} = R$ {{formataPreco(e.valor_parcela_total)}}</small> 
                                        <small>R$ {{formataPreco(e.valor_recebido)}}</small>
                                      </span> 
                                    </td>                                   
                                    
                                    <td class="text-center">
                                      <span v-if="e.cancelado_parcela" class="text-secondary"><small>Cancelado</small> </span>
                                      <span v-else-if="e.pago" class="text-success"><small>Pago</small> </span>
                                      <span v-else-if="verificaVencimento(e.data_vencimento)">                          
                                        <small class="text-danger">Vencido</small>                           
                                      </span>
                                      <span v-else class="text-warning"><small>Pendente</small> </span>
                                    </td> 
                                    <td class="text-center">
                                      <div v-if="e.tipo">
                                        <span v-if="e.tipo == 'M'"><small>Taxa de matrícula</small> </span>
                                        <span v-else-if="e.tipo == 'C'"><small>Curso</small> </span>
                                        <span v-else-if="e.tipo == 'R'"><small>Requerimento</small> </span>
                                        <span v-else-if="e.tipo == 'I'"><small>Item de cobrança</small> </span>
                                        <span v-else-if="e.tipo == 'L'"><small>Curso livre</small> </span>
                                      </div>
                                      <span v-else><small>Curso</small> </span>
                                    </td>                             
                                    
                                  </tr>
                                </tbody> 
                                <tbody v-else>
                                  <tr>
                                    <td colspan="9" class="text-center">
                                      Nenhum boleto encontrado
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="col-12 text-center mt-4">
                              <Pagination
                                :page-size="20"
                                :items="fastPessoaComissao.filtro"
                                @changePage="fastPessoaComissao.paginacao = $event"
                              />
                            </div> 
                        </div>
                      </b-card-text>
                    </b-tab>
                  </b-tabs>
                </b-card>
                
                
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <a
              class="btn btn-red-hollow"
              :href="'/minhas-plataformas'"
            >
              <b-icon-arrow-return-left /> Voltar
            </a>
          </div>
        </section>
      </div>
    </div>
    <!-- Notificações -->
    <notifications
      group="foo"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "./components/Pagination";

export default {
  name: "HomeInternoComissao",
  components: {
    Pagination
  },
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      nomeUsuario: this.retornaSessao(settings.fastSessaoUsuario).First_name,
      sobrenomeUsuario: this.retornaSessao(settings.fastSessaoUsuario)
        .Last_name,
      imageUsuario: this.retornaSessao(settings.fastSessaoUsuario).Image,
      emailUsuario: this.retornaSessao(settings.fastSessaoUsuario).Email,
      nome_plataforma: "",
      n_cursos: "",
      n_cursos_publicados: "",
      n_professores: "",
      banner_plataforma: "",
      logo_plataforma: "",
      // Parametro
      fastUsuarioParametro: {
        id_usuario: "",
        image: "",
        url_zoom: "",
      },
      fastPlataformaUsuario: {
        nome_usuario:
          this.retornaSessao(settings.fastSessaoUsuario).First_name +
          " " +
          this.retornaSessao(settings.fastSessaoUsuario).Last_name,
        alteracao_pendente: false,
      },
      // Pessoa
      fastPessoa: {
        id_pessoa: 0,
        nome_razao: "",
        sobrenome_fantasia: "",
        cpf_cnpj: "",
        datanascimento_abertura: null,
        sexo: "",
        uf: "",
        cep: "",
        logradouro: "",
        bairro: "",
        cidade: "",
        complemento: "",
        numero: "",
        telefone: "",
        payee_code_gerencianet: "",
        alteracao_pendente: false,
        possui_matricula: true
      },
      fastPessoaFinanceiro: {
        id_pessoa: 0,
        id_plataforma: 0,
        banco: "",
        codigo_banco: "",
        payee_code_gerencianet: "",
        loading: false,
        alteracao_pendente: false
      },
      fastPessoaComissao: {
        total: [],
        filtro: [],
        paginacao: [],
        loading: false
      },
      fastFiltro: {
        plataforma: {
          lista: [],
          escolhida: ""
        },
        anoPagamento: {
          lista: ['Todos', (new Date().getFullYear()) - 1, (new Date().getFullYear()), (new Date().getFullYear()) + 1],
          escolhido: 'Todos'
        },
        mesPagamento: {
          lista: ['Todos', 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
          escolhido: 0
        },
        diaPagamento: {
          lista: ['Todos', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
          escolhido: 0
        },        
        status: {
          escolhido: ""
        },
        data_alterada: false
      },
    };
  },
  mounted: function () {
    this.$store.state.fastCarregando = false;
    this.getFastHoraServidor().then(() => this.getInvoiceComissao())
  },
  methods: {
    // Comissão
    async getInvoiceComissao(){
      this.fastPessoaComissao.loading = true
      this.fastPessoaComissao.total = []
      this.fastPessoaComissao.filtro = []
      this.promiseGetFastApi("api/fast_tesouraria_invoice_schema_comissao/lista_plataformas", ``)
      .then((res) => {
        console.log("getInvoiceComissao", res)
        if (res.length) {

          // Filtro de plataformas
            this.fastFiltro.plataforma.lista = Array.from(
              new Set(
                res.map((p) => {
                  return p.nome_plataforma;
                })
              )
            );

          this.fastPessoaComissao.total = res
          this.fastPessoaComissao.filtro = res
        } 
        this.fastPessoaComissao.loading = false
      })
      .catch(e => {
        this.exibeToasty(e, "error")
        this.fastPessoaComissao.loading = false
      })
    },
    alteraDataFiltro(){
      // int id_plataforma, string ano_vencimento, string mes_vencimento, string ano_registro, string mes_registro  
      let mesPagamento = ""
      let anoPagamento = ""
      let diaPagamento = ""

      if (this.fastFiltro.anoPagamento.escolhido != "Todos") anoPagamento = this.fastFiltro.anoPagamento.escolhido
      if (this.fastFiltro.mesPagamento.escolhido) {
        mesPagamento = this.fastFiltro.mesPagamento.escolhido
        mesPagamento = mesPagamento < 10 ? '0' + (mesPagamento).toString() : mes
      }
      if (this.fastFiltro.diaPagamento.escolhido) {
        diaPagamento = this.fastFiltro.diaPagamento.escolhido
        diaPagamento = diaPagamento < 10 ? '0' + (diaPagamento).toString() : diaPagamento
      }

      this.fastPessoaComissao.filtro = this.fastPessoaComissao.total.filter(e => {
        return this.executaFiltro(anoPagamento, mesPagamento, diaPagamento, this.fastFiltro.status.escolhido, e)
      })      
    },
    executaFiltro(anoPagamento, mesPagamento, diaPagamento, status, e){
      let retorno = true

      if (e.data_pagamento) {
        if (anoPagamento) {
          if (e.data_pagamento.split("-")[0] == anoPagamento) retorno = true
          else
          retorno = false
        } 

        if (mesPagamento && retorno) {
          if (parseInt(e.data_pagamento.split("-")[1]) == mesPagamento) retorno = true
          else
          retorno = false
        } 

        if (diaPagamento && retorno) {
          let dia = e.data_pagamento.split("-")[2].split("T")[0]
          if (parseInt(dia) == diaPagamento) retorno = true
          else
          retorno = false
        } 
      } else if (anoPagamento || mesPagamento || diaPagamento) {
        retorno = false
      }      

      if (this.fastFiltro.status.escolhido && retorno) {
        switch (this.fastFiltro.status.escolhido) {
          case 'P':
            // Pago
            if (e.pago && !e.cancelado_parcela) retorno = true
            else
            retorno = false
            break;
          case 'A':
            // Pendente
            if (!e.pago && !e.cancelado_parcela && !this.verificaVencimento(e.data_vencimento)) retorno = true
            else
            retorno = false
            break;
          case 'V':
            // Vencido
            if (!e.pago && !e.cancelado_parcela && this.verificaVencimento(e.data_vencimento)) retorno = true
            else
            retorno = false
            break;
          case 'C':
            // Cancelado
            if (e.cancelado_parcela) retorno = true
            else
            retorno = false
            break;
        
          default:
            break;
        }
      } 

      if (this.fastFiltro.plataforma.escolhida && retorno) {
        if (e.nome_plataforma == this.fastFiltro.plataforma.escolhida) retorno = true
        else
        retorno = false
      }

      return retorno
    },
    exibeTotalPeriodo(){
      let total = 0.00
      this.fastPessoaComissao.filtro.forEach(e => {
        if (!e.cancelado_parcela){
          total += e.valor_recebido
        }        
      })
      return this.formataPreco(total)
    },
    exibeRecebidoPeriodo(){
      let total = 0.00
      this.fastPessoaComissao.filtro.forEach(e => {
        if (e.pago && !e.cancelado_parcela) {
          total += e.valor_recebido
        }        
      })
      return this.formataPreco(total)
    },
    exibeReceberPeriodo(){
      let total = 0.00
      this.fastPessoaComissao.filtro.forEach(e => {
        if (!e.pago && !e.cancelado_parcela) {
          total += e.valor_recebido
        }        
      })
      return this.formataPreco(total)
    },
    exibeRecebimentosAcumulado(){
      let total = 0.00
      this.fastPessoaComissao.total.forEach(e => {
        if (e.pago && !e.cancelado_parcela) {
          total += e.valor_recebido
        }        
      })
      return this.formataPreco(total)
    },
    verificaVencimento(dataVencimento){
      let dataAtual = this.$store.state.fastDataAtualServidor.toLocaleDateString('pt-br').split( '/' ).reverse( ).join( '-' )   
      if (dataVencimento) {
        dataVencimento = new Date(dataVencimento.split("T")[0]).toISOString().slice(0,10);   
      }
      if (dataAtual > dataVencimento) {
        return true
      }
      else {
        return false
      }
      
    },
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}
@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
/*
.card {
  padding-top: 20px;
  margin: 10px 0 20px 0;
  background-color: rgba(214, 224, 226, 0.2);
  border-top-width: 0;
  border-bottom-width: 2px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.card .card-heading {
  padding: 0 20px;
  margin: 0;
}

.card .card-heading.simple {
  font-size: 20px;
  font-weight: 300;
  color: #777;
  border-bottom: 1px solid #e5e5e5;
}

.card .card-heading.image img {
  display: inline-block;
  width: 46px;
  height: 46px;
  margin-right: 15px;
  vertical-align: top;
  border: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.card .card-heading.image .card-heading-header {
  display: inline-block;
  vertical-align: top;
}

.card .card-heading.image .card-heading-header h3 {
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  color: #262626;
}

.card .card-heading.image .card-heading-header span {
  font-size: 12px;
  color: #999999;
}

.card .card-body {
  padding: 0 20px;
  margin-top: 20px;
}

.card .card-media {
  padding: 0 20px;
  margin: 0 -14px;
}

.card .card-media img {
  max-width: 100%;
  max-height: 100%;
}

.card .card-actions {
  min-height: 30px;
  padding: 0 20px 20px 20px;
  margin: 20px 0 0 0;
}

.card .card-comments {
  padding: 20px;
  margin: 0;
  background-color: #f8f8f8;
}

.card .card-comments .comments-collapse-toggle {
  padding: 0;
  margin: 0 20px 12px 20px;
}

.card .card-comments .comments-collapse-toggle a,
.card .card-comments .comments-collapse-toggle span {
  padding-right: 5px;
  overflow: hidden;
  font-size: 12px;
  color: #999;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-comments .media-heading {
  font-size: 13px;
  font-weight: bold;
}

.card.people {
  position: relative;
  display: inline-block;
  width: 170px;
  height: 300px;
  padding-top: 0;
  margin-left: 20px;
  overflow: hidden;
  vertical-align: top;
}

.card.people:first-child {
  margin-left: 0;
}

.card.people .card-top {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 170px;
  height: 150px;
  background-color: #ffffff;
}

.card.people .card-top.green {
  background-color: #53a93f;
}

.card.people .card-top.blue {
  background-color: #427fed;
}

.card.people .card-info {
  position: absolute;
  top: 150px;
  display: inline-block;
  width: 100%;
  height: 101px;
  overflow: hidden;
  background: #ffffff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.card.people .card-info .title {
  display: block;
  margin: 8px 14px 0 14px;
  overflow: hidden;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  color: #404040;
}

.card.people .card-info .desc {
  display: block;
  margin: 8px 14px 0 14px;
  overflow: hidden;
  font-size: 12px;
  line-height: 16px;
  color: #737373;
  text-overflow: ellipsis;
}

.card.people .card-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  padding: 10px 20px;
  line-height: 29px;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.card.hovercard {
  position: relative;
  padding-top: 0;
  overflow: hidden;
  text-align: center;
  background-color: rgba(214, 224, 226, 0.2);
}

.card.hovercard .cardheader {
  background-size: cover;
  height: 135px;
}

.card.hovercard .avatar {
  position: relative;
  top: -50px;
  margin-bottom: -50px;
}

.card.hovercard .avatar img {
  width: 100px;
  height: 100px;
  max-width: 100px;
  max-height: 100px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.5);
}

.card.hovercard .info {
  padding: 4px 8px 10px;
}

.card.hovercard .info .title {
  margin-bottom: 4px;
  font-size: 24px;
  line-height: 1;
  color: #262626;
  vertical-align: middle;
}

.card.hovercard .info .desc {
  overflow: hidden;
  font-size: 12px;
  line-height: 20px;
  color: #737373;
  text-overflow: ellipsis;
}

.card.hovercard .bottom {
  padding: 0 20px;
  margin-bottom: 17px;
}

}*/
</style>
